.privacy {
  padding: 1rem 0 4rem;

  .container {
    max-width: 1140px;
  }

  h2 {
    font-size: 42px;
    font-weight: 900;
    margin-bottom: 24px;
    text-align: left;
    display: inline;

    & + span {
      display: block;
      font-size: 14px;
      font-weight: 300;
    }
  }

  h3 {
    font-size: 32px;
    font-weight: 900;
    margin-bottom: 16px;
    margin-top: 32px;
    text-align: left;
  }

  h4 {
    font-size: 22px;
    text-align: left;
    margin: 1.5rem 0 .5rem;
  }

  p {
    margin-bottom: 16px;
  }

  a {
    text-decoration: underline;
    color: inherit;

    &:hover {
      text-decoration: none;
    }
  }

  ul, ol {
    margin-bottom: 36px;
    padding-left: 16px;

    li {
      color: #f8e3ff;
      margin-bottom: 9px;
    }
  }

  ol {
    list-style-type: decimal;
    padding-left: 40px;
  }
}
