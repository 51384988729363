.UseCase {
  color: #6f6675;
  text-align: center;

  div {
    display: flex;
    justify-content: center;
    align-items: center;
    background-color: #fff;
    position: relative;
    height: 9rem;
    width: 8rem;
    padding: 0.85rem;
    margin: 0 auto 1rem;
    transition: all .25s ease;
    background: transparent url('../../images/polygon.svg') no-repeat center;
    background-size: 100%;

    &:hover {
      transition: all .25s ease;
      transform: scale(1.11);
    }

    img {
      width: 60%;
      display: block;
      margin-top: -16px;
    }
  }

  h3 {
    font-weight: 800;
    font-size: 26px;
    line-height: 39px;
  }
}
