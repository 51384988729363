.Awards {
  @media only screen and (max-width: 768px) {
    padding-top: 0 !important;
  }

  & > div {
    padding-bottom: 3rem;
  }

  .AwardInner {
    min-height: 13rem;
    display: flex;
    justify-content: center;
    align-items: center;
  }

  img {
    width: 100%;
    max-width: 170px;
    display: block;

    &.FirstAward {
      max-width: 220px;
    }
  }

  p {
    font-size: 14px;
    text-align: center;
  }
}
