@import '~bootstrap/scss/bootstrap.scss';

.Content {
  @extend .col-lg-6;
  @extend .order-lg-first;

  h1 {
    margin-bottom: 20px;
    text-align: left;
  }

  p {
    color: #f8e3ff;
    font-size: 22px;
    line-height: 30px;
  }

  [class~=btn] {
    margin-top: 20px;

    @media only screen and (max-width: 992px) {
      display: block;
      max-width: 16rem;
      margin: 0 auto;
      padding: 13px 35px;
      font-size: 1.15rem;
    }
  }
}
