.quote {
  color: #6f6675;

  .slider {
    padding: 12px 0 102px;

    &__inner {
      max-width: 100% !important;
      background-color: #190e2b;
      padding: 62px 23px 22px;
      text-align: center;
      box-shadow: 0px 4px 10px rgba(0, 0, 0, 0.1);
      width: 344px !important;
      margin: 7rem auto 0;
      position: relative;
      border-radius: 6px;

      &--image {
        width: 94px;
        height: 94px;
        margin: 0 auto;
        border-radius: 100%;
        text-align: center;
        position: relative;
        top: -7rem;
        zoom: 1.5;
        padding: 1em;
        background: linear-gradient(301.46deg, #EF3355 30.86%, #C766F4 73.16%);

        img {
          width: 84px;
          height: 84px;
          display: block;
          margin: 0 auto;
          border-radius: 100%;
          position: relative;
          top: -11px;
          left: -11px;
        }
      }

      &--text {
        margin-top: -6rem;
        position: relative;
        z-index: 1;

        h5 {
          font-weight: 800;
          font-size: 21px;
          line-height: 25px;
          color: #f8e3ff;
          background-image: none;
          background-clip: unset;
          -webkit-text-fill-color: unset;
        }
      }

      .quote {
        display: block;
        width: 100%;
        margin: 1.5rem 0;
      }

      .title-style {
        height: 100px;
      }

      .text-style {
        height: 240px;
      }
    }

    .slick-list {
      padding: 0 0 1.25rem;

      .slick-track {
        display: flex !important;
        flex-wrap: wrap;

        .slick-slide {
          height: auto;

          & > div {
            height: 100%;
            text-align: center;
          }
        }
      }
    }

    .slick-dots {
      width: 100%;
      display: flex;
      align-items: center;
      justify-content: center;
      bottom: 2rem;

      li {
        width: 8px;
        height: 8px;
        border-radius: 100px;
        margin: 0 8px;
        transition: width .2s ease;
        background: #DA4DA8;

        &.slick-active {
          width: 45px;
          background: #C665F4;
        }

        button {
          &:before {
            display: none;
          }
        }
      }
    }
  }
}
