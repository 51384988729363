header {
  position: fixed;
  top: 0;
  left: 0;
  right: 0;
  z-index: 200;
  color: #442458;
  backdrop-filter: blur(4px);
  background-color: rgba(46, 21, 61, .8);
  box-shadow: 0 0 100px hsla(0, 0%, 0%, .3);

  @media only screen and (max-width: 1280px) {
    padding: 6px 0;
  }

  @media only screen and (max-width: 992px) {
    text-align: center;
  }

  .navbar {
    position: relative;
  }

  .navbar-toggler {
    background: transparent;
    border: 0;

    .navbar-toggler-icon {
      background-image: none;
      height: 1px;
      border: 1px solid #c766f4;
      border-radius: 4px;

      &:before,
      &:after {
        content: '';
        display: block;
        height: 1px;
        border: 1px solid #c766f4;
        border-radius: 4px;
        position: relative;
        width: 1.5em;
        left: -1px;
      }

      &:before {
        top: -12px;
      }

      &:after {
        bottom: -7px;
      }
    }
  }

  .btn {
    font-size: 13px;
    font-style: normal;
    font-weight: 700;
    padding: 9px 49px;
  }

  .product-nav {
    text-align: left;
    padding: 1rem 0 0;

    &.only-mobile {
      display: none;

      @media only screen and (max-width: 992px) {
        display: block;
      }
    }

    h3 {
      font-size: 1.25rem !important;
      color: #f8e3ff;

      &:nth-of-type(2) {
        margin-top: 2rem !important;
      }
    }

    li {
      display: block;
      padding: 14px 0;
      font-size: 22px;

      a {
        display: block;
        width: 100%;

        img {
          display: block;
          height: auto;
          max-width: 100%;
        }
      }

      &:nth-child(2) {
        img {
          width: 280px;
        }
      }

      &:nth-child(3) {
        img {
          width: 214px;
        }
      }

      &:nth-child(4) {
        img {
          width: 302px;
        }
      }

      &:nth-child(5) {
        img {
          width: 285px;
        }
      }

      &:nth-child(7) {
        img {
          width: 226px;
        }
      }
    }
  }

  #menuToggle {
    display: block;
    position: absolute;
    top: 21px;
    left: 25px;
    z-index: 1;
    -webkit-user-select: none;
    user-select: none;
    transform: scale(0.8);

    &.only-desktop {
      @media only screen and (max-width: 992px) {
        display: none;
      }
    }

    @media only screen and (max-width: 420px) {
      left: 10px;
    }

    input {
      display: block;
      width: 40px;
      height: 32px;
      position: absolute;
      top: -7px;
      left: -5px;
      cursor: pointer;
      opacity: 0;
      z-index: 2;
      -webkit-touch-callout: none;

      &:checked ~ span {
        opacity: 1;
        transform: rotate(45deg) translate(-2px, -1px);
        background: #f8e3ff;

        &:nth-last-child(3) {
          opacity: 0;
          transform: rotate(0deg) scale(0.2, 0.2);
        }

        &:nth-last-child(2) {
          opacity: 1;
          transform: rotate(-45deg) translate(0, -1px);
        }
      }

      &:checked ~ ul {
        transform: none;
        opacity: 1;
      }
    }

    span {
      display: block;
      width: 33px;
      height: 4px;
      margin-bottom: 5px;
      position: relative;
      background: #cdcdcd;
      border-radius: 3px;
      z-index: 1;
      transform-origin: 4px 0px;
      transition: transform 0.5s cubic-bezier(0.77,0.2,0.05,1.0),
                  background 0.5s cubic-bezier(0.77,0.2,0.05,1.0),
                  opacity 0.55s ease;
      &:first-child {
        transform-origin: 0% 0%;
      }

      &:nth-last-child(2) {
        transform-origin: 0% 100%;
      }
    }
  }

  #menu {
    position: absolute;
    width: 370px;
    height: 200vh;
    background-image: linear-gradient(35deg,
      rgb(28, 12, 34) 0%,
      rgb(34, 11, 50) 24%,
      rgb(38, 10, 61) 35%,
      rgb(40, 8, 68) 43%,
      rgb(46, 7, 80) 50%,
      rgb(49, 5, 87) 57%,
      rgb(55, 5, 77) 65%,
      rgb(65, 5, 72) 72%,
      rgb(72, 5, 62) 81%,
      rgb(77, 5, 49) 90%,
      rgb(82, 5, 33) 100%);
      box-shadow: inset 0 0 100px hsla(0, 0%, 0%, .3);
    margin: -100px 0 0 0;
    padding: 40px 30px;
    padding-top: 125px;
    left: -50px;
    list-style-type: none;
    transform-origin: 0% 0%;
    transform: translate(-100%, 0);
    transition: transform 0.5s cubic-bezier(0.77,0.2,0.05,1.0);

    h3 {
      color: #f8e3ff;
      margin-left: 14px;
    }

    li {
      padding: 10px 0;
      font-size: 22px;
      transform: scale(0.7) translateX(-50px);

      a {
        display: block;
        width: 100%;
      }

      img {
        display: block;
      }
    }
  }

  .navbar-collapse {
    display: flex;
    align-items: center;
    height: 100%;

    @media only screen and (max-width: 992px) {
      display: block;
    }
  }

  .logo {
    cursor: pointer;
    margin-right: 50px;

    @media only screen and (max-width: 1280px) {
      margin-left: 30px;
    }

    @media only screen and (max-width: 768px) {
      margin-right: 0;
      margin-left: 0;
    }

    img {
      width: 265px;
    }

    a {
      text-decoration: none;

      &:hover {
        text-decoration: none;
      }
    }
  }

  .navbar-light {
    .navbar-nav {
      .nav-link {
        color: #f8e3ff;
        cursor: pointer;
        display: block;
        text-decoration: none;
        text-transform: uppercase;
        font-style: normal;
        font-weight: 700;
        font-size: 15px;
        line-height: 18px;
        transition: all .25s ease;

        &:hover {
          text-decoration: none;
          opacity: 1;
          transition: all .25s ease;
          background-image: linear-gradient(340deg, #EF3355 30%, #C766F4 73%);
          color: transparent;
          background-clip: text;
        }

        @media only screen and (max-width: 992px) {
          font-size: 1.25rem !important;
          text-align: left;
          text-transform: none;
          font-size: 26px;
          font-weight: 500;
          padding: 26px 0 20px;
          line-height: 1.2;
        }
      }

      .btn {
        @media only screen and (max-width: 992px) {
          display: inline-block;
          max-width: 12rem;
        }
      }
    }
  }
}
