@import url('https://fonts.googleapis.com/css2?family=Karla:wght@300;500;600;700;800&display=swap');

body {
  font-family: 'Karla', sans-serif;
  -webkit-font-smoothing: antialiased;
  -moz-osx-font-smoothing: grayscale;

  main {
    padding-top: 4rem;
    position: relative;
    z-index: 50;
    background: #3b1c4e;
    margin-bottom: 88px;

    @media only screen and (max-width: 992px) {
      margin-bottom: 118px;
      overflow-x: hidden;
    }

    section {
      h1, h2, h3, h4, h5 {
        background-image: linear-gradient(340deg, #EF3355 30%, #C766F4 73%);
        color: transparent;
        background-clip: text;
        font-family: "Karla", sans-serif;
        font-weight: 800;
        text-transform: uppercase;
        text-align: center;
      }

      h1 {
        font-family: 'NunitoSansBlack';
        font-size: 60px;
        font-style: normal;
        font-weight: 800;
        line-height: 65px;
        text-transform: uppercase;
        animation: move 5s linear infinite;
        background-image: linear-gradient(to right, #EF3355, #C766F4, #8C66F4, #C766F4, #EF3355);
        background-size: 200% auto;

        @keyframes move {
          to {
            background-position: 200% center;
          }
        }

        @media only screen and (max-width: 1198px) {
          font-size: 50px;
          line-height: 55px;
        }

        @media only screen and (max-width: 576px) {
          font-size: 2.8rem;
          line-height: 3.25rem;
        }
      }

      h3 {
        font-weight: 700;
      }

      h5 {
        font-size: 1.25rem;
        line-height: 1.35;
      }

      p {
        color: #f8e3ff;

        a {
          color: #DC4BA1;
          text-decoration: underline;
        }
      }

      ul {
        li {
          color: #f8e3ff;
          list-style: circle;
        }
      }
    }
  }

  .grecaptcha-badge {
    display: none;
  }

  @-webkit-keyframes fadeInUp {
    0% {
      opacity: 0;
      -webkit-transform: translate3d(0, 3.5%, 0);
      transform: translate3d(0, 3.5%, 0);
    }

    to {
      opacity: 1;
      -webkit-transform: translateZ(0);
      transform: translateZ(0);
    }
  }

  @keyframes fadeInUp {
    0% {
      opacity: 0;
      -webkit-transform: translate3d(0, 3.5%, 0);
      transform: translate3d(0, 3.5%, 0);
    }

    to {
      opacity: 1;
      -webkit-transform: translateZ(0);
      transform: translateZ(0);
    }
  }

  .fadeInUp {
    -webkit-animation-name: fadeInUp;
    animation-name: fadeInUp
  }
}
