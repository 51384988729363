.Slider {
  .slick-slider {
    padding: 24px 0 84px 0;

    @media only screen and (min-width: 1200px) {
      padding: unset;
    }

    @media only screen and (max-width: 576px) {
      padding: 0 0 84px 0;
    }

    .slick-dots {
      width: 100%;
      display: flex;
      align-items: center;
      justify-content: center;
      bottom: 2rem;

      li {
        width: 8px;
        height: 8px;
        border-radius: 100px;
        margin: 0 8px;
        transition: width .2s ease;
        background: #DA4DA8;

        &.slick-active {
          width: 45px;
          background: #C665F4;
        }

        button {
          &:before {
            display: none;
          }
        }
      }
    }
  }
}
