@import '~bootstrap/scss/bootstrap.scss';

@import '../../styles/effects.scss';

.PostCard {
  @extend .card;
  @extend .h-100;

  background-color: #190e2b;
  border: 0;
  border-radius: unset;
  box-shadow: 0 12px 24px 0 rgba(0, 0, 0, 0.141176);
  color: #6f6675;
}

.CardTitle {
  color: #f8e3ff;
  text-align: left;
  font-size: 23px;
  font-weight: 800;
  min-height: 85px;
  text-transform: none;

  a {
    color: inherit;
    text-decoration: none;

    &:hover {
      @extend .GradientTextColor;
    }
  }
}

.CardText {
  @extend .card-text;

  font-size: 16px;
  font-weight: 400;

  p {
    display: -webkit-box;
    margin: 0;
    overflow: hidden;
    -webkit-box-orient: vertical;
    -webkit-line-clamp: 4;
  }
}

.CardFooter {
  @extend .card-footer;
  @extend .border-0;
  @extend .my-2;

  background-color: #190e2b;
  color: #716976;

  div {
    @extend .d-flex;
    @extend .flex-row;
    @extend .pt-3;

    border-top: 1px solid rgba(155, 155, 155, 0.3) !important;
  }
}

.ReadMore,
.ReadMore:hover {
  @extend .GradientTextColor;

  font-size: 16px;
  font-weight: 700;
  text-decoration: none;
}
