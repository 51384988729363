// NUNITO SANS

@font-face {
  font-family: 'NunitoSansBlack';
  src: url('../../fonts/nunito-sans/NunitoSans-Black.ttf') format('truetype');
}

@font-face {
  font-family: 'NunitoSansBlackItalic';
  src: url('../../fonts/nunito-sans/NunitoSans-BlackItalic.ttf') format('truetype');
}

@font-face {
  font-family: 'NunitoSansBold';
  src: url('../../fonts/nunito-sans/NunitoSans-Bold.ttf') format('truetype');
}

@font-face {
  font-family: 'NunitoSansBoldItalic';
  src: url('../../fonts/nunito-sans/NunitoSans-BoldItalic.ttf') format('truetype');
}

@font-face {
  font-family: 'Extrabold';
  src: url('../../fonts/nunito-sans/NunitoSans-ExtraBold.ttf') format('truetype');
}

@font-face {
  font-family: 'ExtraboldItalic';
  src: url('../../fonts/nunito-sans/NunitoSans-ExtraBoldItalic.ttf') format('truetype');
}
