.prototypevideo {
  height: 110vh;
  min-height: 50rem;

  .back-to-home {
    @media only screen and (max-width: 1280px) {
      margin-top: 2rem;
      z-index: 40;
    }
  }

  h1, p {
    text-align: center;
  }

  h1 {
    margin-bottom: 1.5rem;
  }

  .fluidMedia {
    position: relative;
    padding-bottom: 40%;
    height: 0;
    overflow: hidden;
    width: 75%;
    margin: 2rem auto;

    iframe {
      position: absolute;
      top: 0;
      left: 0;
      width: 100%;
      height: 100%;
    }
  }

  & + .footer {
    position: absolute;
    bottom: -6rem;
  }
}
