.card-img {
  border-radius: unset;
  position: relative;
  overflow: hidden;
  transition: all 0.3s ease-in-out;

  &:before {
    position: absolute;
    top: 0;
    left: -75%;
    z-index: 2;
    display: block;
    content: "";
    width: 50%;
    height: 100%;
    background: linear-gradient(
      to right,
      rgba(255, 255, 255, 0) 0%,
      rgba(255, 255, 255, 0.3) 100%
    );
    transform: skewX(-25deg);
  }

  img {
    border-radius: unset;
    max-height: 240px;
    min-height: 240px;
    object-fit: cover;
    max-width: 100%;
    transition: all 0.3s ease-in-out;

    @media only screen and (max-width: 992px) {
      max-height: none;
      min-height: 240px;
    }

    @media only screen and (max-width: 768px) {
      max-height: none;
      min-height: unset;
    }
  }

  &:hover {
    &:before {
      animation: shine 0.75s;
    }

    img {
      transform: scale(1.1);
      transition: all 0.3s ease-in-out;
    }
  }
}

@-webkit-keyframes shine {
  100% {
    left: 125%;
  }
}

@keyframes shine {
  100% {
    left: 125%;
  }
}
