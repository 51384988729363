.back-to-home {
  font-weight: 500;
  font-size: 16px;
  line-height: 20px;
  color: #6B6868;
  position: relative;
  z-index: 40;
  margin-bottom: 2rem;

  button {
    background: transparent;
    border: 0;
    color: #000;
  }

  svg {
    display: inline-block;
    margin-right: 9px;
    transition: all .25s ease;
  }

  &:hover {
    svg {
      transform: translate(-8px);
      transition: all .25s ease;
    }
  }
}
