.Hero {
  display: flex;
  justify-content: center;
  align-items: center;
  min-height: 94vh;
  padding-top: 0 !important;

  @media only screen and (max-width: 992px) {
    height: auto;
    padding: 4rem 0 10rem;
    background-image: none;
  }

  @media only screen and (max-width: 576px) {
    padding: 2rem 0 0 !important;
    min-height: auto;
  }
}
