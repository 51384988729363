.demoSlider {
  margin-bottom: 2rem;

  p {
    margin-top: 2rem;
  }

  img {
    display: block;
    margin: 0 auto;
    width: 100%;
  }

  .slick-list {
    position: relative;

    &:before,
    &:after {
      content: '';
      position: absolute;
      z-index: 200;
      top: 0;
      bottom: 0;
      display: block;
      width: 120px;

      @media only screen and (max-width: 768px) {
        width: 60px;
      }
    }

    &:before {
      left: 0;
      background: rgb(59, 28, 78);
      background: linear-gradient(90deg, rgba(59, 28, 78 ,1) 25%, rgba(59, 28, 78, 0) 100%);
    }

    &:after {
      right: 0;
      background: rgb(59, 28, 78);
      background: linear-gradient(90deg, rgba(59, 28, 78, 0) 25%, rgba(59, 28, 78, 1) 100%);
    }

    .slick-track {
      display: flex !important;
      flex-wrap: wrap;

      .slick-slide {
        height: auto;
        padding: 0 2rem;

        & > div {
          height: 100%;
          text-align: center;
        }
      }
    }
  }

  .slick-arrow {
    height: 100%;
    width: 12rem;
    z-index: 50;

    &:before {
      display: none;
    }
  }

  .slick-dots {
    width: 100%;
    display: flex;
    align-items: center;
    justify-content: center;
    bottom: -2.25rem;

    li {
      width: 8px;
      height: 8px;
      border-radius: 100px;
      margin: 0 8px;
      transition: width .2s ease;
      background: #DA4DA8;

      &.slick-active {
        width: 45px;
        background: #C665F4;
      }

      button {
        &:before {
          display: none;
        }
      }
    }
  }
}
