.staycompliant {

  h1 {
    @media only screen and (max-width: 768px) {
      font-size: 2rem;
      line-height: 2.5rem;
    }
  }

  h2 {
    @media only screen and (max-width: 768px) {
      font-size: 2rem;
    }
  }

  #prevent {
    background-image: linear-gradient(35deg,
      rgb(28, 12, 34) 0%,
      rgb(34, 11, 50) 24%,
      rgb(38, 10, 61) 35%,
      rgb(40, 8, 68) 43%,
      rgb(46, 7, 80) 50%,
      rgb(49, 5, 87) 57%,
      rgb(55, 5, 77) 65%,
      rgb(65, 5, 72) 72%,
      rgb(72, 5, 62) 81%,
      rgb(77, 5, 49) 90%,
      rgb(82, 5, 33) 100%);
    box-shadow: inset 0 0 100px hsla(0, 0%, 0%, .3);

    @media only screen and (max-width: 768px) {
      padding-bottom: 0 !important;
    }
  }

  .intro {
    margin: 3rem auto;
    width: 65rem;
    max-width: 100%;
    font-weight: 700;
    font-size: 24px;
    line-height: 29px;
  }
}

.dark {
  background: #32123E;
}
