@import '../../styles/effects';

.BookMeeting {
  background-color: #281236;
  border-radius: 8px;
  border: 1px solid #DC4BA1;
  box-sizing: border-box;
  color: #ffffff;
  margin: 3rem auto 0 auto;
  padding-bottom: 2rem;
  text-align: center;
  width: calc(min(350px, 90vw));

  @media only screen and (min-width: 1260px) {
    position: absolute;
    left: 50%;
    top: 0rem;
    transform: translate(-620px);
    z-index: 1010;
  }

  h4 {
    @extend .GradientBg;
    border-radius: inherit;
    border-bottom-left-radius: unset;
    border-bottom-right-radius: unset;
    background-clip: unset;
    -webkit-background-clip: unset;
    color: #ffffff !important;
    -webkit-text-fill-color: #ffffff !important;
    padding: 0.5rem 0;
  }

  p {
    font-size: 14pt;
    padding: 1rem 1rem;
  }

  a {
    @extend .GradientButton;

    font-size: 1rem;
    font-weight: 700;
    text-transform: uppercase;
  }
}
