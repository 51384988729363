.Feature {
  display: flex;
  justify-content: flex-start;
  align-items: center;
  text-align: left;
  height: 100%;
  margin-bottom: 40px;
  padding: 25px 35px;
  background: #190e2b;
  box-shadow: 0px 4px 10px rgba(0, 0, 0, 0.1);
  transition: all .25s ease;

  &:hover {
    transform: scale(1.02);
    transition: all .25s ease;
  }

  h6 {
    font-weight: 700;
    font-size: 26px;
    line-height: 39px;
    color: #ded5e3;
    padding-left: 25px;
    margin-bottom: 0;
  }

  p {
    font-weight: 400;
    font-size: 16px;
    line-height: 24px;
    color: #ded5e3;
    margin: 0;
    padding-left: 25px;
  }

  h6 {
    font-weight: bold;
  }

  img {
    max-width: 4.5rem;
  }
}
