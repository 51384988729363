.Contact {
  background: url('../../images/contact_bg.svg') center bottom no-repeat;
  background-color: #281236;
  background-size: auto;
  position: relative;

  h2 {
    font-size: 48px;
    font-weight: 800;
    max-width: 500px !important;
    margin: 0 auto;
  }

  .form {
    margin: 0 auto;
    background: transparent !important;
    padding: 0 !important;

    .description {
      color: white;
      font-size: 14pt;
      text-align: center;
      margin-bottom: 2rem;
      margin-left: auto;
      margin-right: auto;
      width: calc(min(500px, 100%));
    }

    label {
      font-weight: normal;
      font-size: 16px;
      line-height: 22px;
      color: #ffffff;

      small {
        margin-left: 7px;
        color: #bcbcbc;
      }
    }

    &__inner {
      width: calc(min(494px, 100%));
      margin: 0 auto;

      input[type="text"],
      input[type="email"],
      textarea {
        margin-bottom: 1rem;
        width: 100%;
        background: #ffffff;
        border-radius: 5px;
        border: 0;
        padding: 8px 12px;
        transition: all .25s ease;

        &:focus {
          outline: none;
          transform: scale(1.008);
          transition: all .25s ease;
        }
      }

      textarea {
        min-height: 8rem;
      }

      p {
        text-align: center;
      }

      a {
        color: #DC4BA1;
        text-decoration: underline;
      }

      .check {
        text-align: center;
        padding-top: 12px;
        margin-bottom: 25px;

        input {
          margin-right: 12px;
        }
      }

      .submit-container {
        text-align: center;
        margin-bottom: 15px;

        .btn {
          border-radius: 999px;
          padding: 15px 118px;
          text-transform: uppercase;

          @media only screen and (max-width: 670px) {
            width: 100%;
            padding: 15px;
          }
        }
      }
    }
  }
}
