html,
body,
#root {
  min-height: 100vh;
}

html {
  overflow-y: scroll;
}

body {
  font-family: 'Karla', sans-serif !important;
  -webkit-font-smoothing: antialiased;
  -moz-osx-font-smoothing: grayscale;
}

#root {
  background-color:#3b1c4e;
  display: flex;
  flex-direction: column;
}

ul {
  list-style: none;

  li {
    list-style: none;

    a {
      text-decoration: none;
    }
  }
}

#LeadboosterContainerHidden {
  display: none !important;
}
