@import '~bootstrap/scss/bootstrap.scss';

@import '../../styles/effects.scss';

.Title {
  @extend .mx-auto;
  font-family: "Karla", sans-serif;
  font-size: 48px;
  font-weight: 800;
  text-transform: uppercase;
  margin-bottom: 2rem;
  text-align: center;
  background-image: linear-gradient(340deg, #EF3355 30%, #C766F4 73%);
  color: transparent;
  background-clip: text;

  @media only screen and (max-width: 576px) {
    font-size: 2.25rem;
  }
}
