.dashboard {
  padding: 3rem 0;
  margin-bottom: 2rem;

  h2 {
    font-size: 48px;
    font-weight: 800;
  }

  p {
    text-align: center;
    margin-bottom: 3rem;
    padding: 0 10rem;
    font-weight: 400;

    @media only screen and (max-width: 992px) {
      padding: 0 2rem;
    }

    @media only screen and (max-width: 768px) {
      padding: 0;
    }
  }

  .slider {
    padding: 50px 120px;

    @media only screen and (max-width: 1399px) {
      padding: 105px 190px;
    }

    @media only screen and (max-width: 992px) {
      background: none;
      padding: 0;
    }
  }

  &__item {
    padding: 0 2rem;

    img {
      display: block;
      width: 95%;
      margin: 0 auto;

      @media only screen and (max-width: 992px) {
        width: 100%;
      }
    }
  }

  .slick-arrow {
    z-index: 90;
    transform: scale(1.5);

    &:before {
      content: '';
    }

    &.slick-next {
      background: transparent url('../../images/arrow-next.svg') center no-repeat;
      background-size: 100%;
      right: 60px;
      top: 45%;

      @media only screen and (max-width: 992px) {
        right: 0;
      }

      @media only screen and (max-width: 576px) {
        top: 40%;
      }
    }

    &.slick-prev {
      background: transparent url('../../images/arrow-prev.svg') center no-repeat;
      background-size: 100%;
      left: 60px;
      top: 45%;

      @media only screen and (max-width: 992px) {
        left: 0;
      }

      @media only screen and (max-width: 576px) {
        top: 40%;
      }
    }
  }

  .slick-list {
    padding: 0 12rem 1.25rem;
    margin-top: -30px;
    text-align: center;
    display: flex;
    flex-direction: column;
    justify-content: center;
    align-items: center;
    overflow: hidden;

    @media only screen and (max-width: 992px) {
      padding: 0;
    }

    .slick-track {
      display: flex !important;
      flex-wrap: wrap;

      .slick-slide {
        height: auto;

        & > div {
          height: 100%;
          text-align: center;
        }
      }
    }
  }

  .slick-dots {
    width: 100%;
    display: flex;
    align-items: center;
    justify-content: center;
    bottom: -3.25rem;
    left: 0;
    right: 0;

    li {
      width: 8px;
      height: 8px;
      border-radius: 100px;
      margin: 0 8px;
      transition: width .2s ease;
      background: #DA4DA8;

      &.slick-active {
        width: 45px;
        background: #C665F4;
      }

      button {
        &:before {
          display: none;
        }
      }
    }
  }
}
