.UseCases {
  background-image: url('../../images/bg_left.svg'),
      url('../../images/bg_right.svg');
  background-repeat: no-repeat,no-repeat;
  background-position: 0% 50%, 100% 50%;
  background-size: contain, contain;
  text-align: center;

  @media only screen and (max-width: 992px) {
    background-image: url('../../images/bg_left.svg');
    padding-top: 0 !important;
    padding-bottom: 0 !important;
  }
}
