.AwardSlider {
  .slick-slide {
    height: 100%;
  }

  .slick-dots {
    width: 100%;
    display: flex;
    align-items: center;
    justify-content: center;
    margin-top: 36px;
    bottom: -3rem;

    li {
      width: 8px;
      height: 8px;
      border-radius: 100px;
      margin: 0 8px;
      transition: width .2s ease;
      background: #DA4DA8;

      &.slick-active {
        width: 45px;
        background: #C665F4;
      }

      button {
        &:before {
          display: none;
        }
      }
    }
  }
}
