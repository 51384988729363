.Link {
  a,
  a:active,
  a:visited {
    color: #DC4BA1;
    text-decoration: none;
  }

  a:hover {
    color: #DC4BA1;
    text-decoration: underline;
  }
}

.Title {
  margin-top: 4rem;
  text-align: center;
  padding-bottom: 2rem;
  background-image: linear-gradient(340deg, #EF3355 30%, #C766F4 73%);
  color: transparent;
  background-clip: text;
  font-family: 'Karla', sans-serif;
  font-size: 40px;
  font-weight: 800;
  text-transform: uppercase;
}
