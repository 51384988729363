@mixin box-shadow($params...) {
  -webkit-box-shadow: $params;
  -moz-box-shadow: $params;
  box-shadow: $params;
}

.steps {
  padding-top: 120px;

  @media only screen and (max-width: 576px) {
    padding-top: 60px;
  }

  h1 {
    text-align: center;
    padding-bottom: 120px;

    @media only screen and (max-width: 576px) {
      padding-bottom: 3rem;
    }
  }

  &__inner {
    position: relative;
    z-index: 12;

    &--step {
      display: flex;
      justify-content: space-between;
      align-items: center;
      padding-bottom: 150px;
      position: relative;

      @media only screen and (max-width: 992px) {
        display: block;
      }

      &:nth-child(1) {
        &:after {
          content: url(../../images/decor-1.svg);
          position: absolute;
          top: 21rem;
          left: 41rem;

          @media only screen and (max-width: 1400px) {
            top: 18rem;
            left: 35.5rem;
          }

          @media only screen and (max-width: 1200px) {
            top: 16.5rem;
            left: 29.75rem;
          }

          @media only screen and (max-width: 992px) {
            display: none;
          }
        }

        .step-content {
          @include box-shadow(-11px 11px 0 0 #C766F4, 0px 0px 6px 2px rgba(0,0,0,0.1));

          &:before {
            content: url(../../images/step-decor-1.svg);
            position: absolute;
            top: -10px;
            right: -25px;

            @media only screen and (max-width: 768px) {
              top: -22px;
              right: -28px;
            }
          }
        }

        .step-img {
          &:before {
            border: 2px solid #B05AD7;
          }
        }
      }

      &:nth-child(2) {
        @media only screen and (max-width: 992px) {
          display: flex;
          flex-direction: column;

          .step-content {
            order: 2;
          }

          .step-img {
            order: 1;
          }
        }

        &:after {
          content: url(../../images/decor-2.svg);
          position: absolute;
          top: 19.5rem;
          left: 19.5rem;

          @media only screen and (max-width: 1400px) {
            top: 18rem;
            left: 13.2rem;
          }

          @media only screen and (max-width: 1200px) {
            top: 16rem;
            left: 6.7rem;
          }

          @media only screen and (max-width: 992px) {
            display: none;
          }
        }

        .step-content {
          @include box-shadow(11px 11px 0 0 #D74A9C, 0px 0px 6px 2px rgba(0,0,0,0.1));
          left: 80px;
          z-index: 80;
          text-align: right;

          &:before {
            content: url(../../images/step-decor-2.svg);
            position: absolute;
            top: -10px;
            left: -25px;
          }

          @media only screen and (max-width: 992px) {
            margin-bottom: 3rem;
            left: unset;
          }
        }

        .step-img {
          &:before {
            right: 108px;
            bottom: -10px;
            border: 2px solid #C3438D;

            @media only screen and (max-width: 1400px) {
              right: 98px;
            }

            @media only screen and (max-width: 992px) {
              right: 118px;
            }

            @media only screen and (max-width: 768px) {
              right: 88px;
            }

            @media only screen and (max-width: 576px) {
              right: unset;
              left: -8px;
            }
          }

          &:after {
            bottom: 34px;
            left: 110px;

            @media only screen and (max-width: 1400px) {
              left: 95px;
            }

            @media only screen and (max-width: 1200px) {
              left: 83px;
            }

            @media only screen and (max-width: 992px) {
              left: 115px;
            }

            @media only screen and (max-width: 768px) {
              left: 87px;
            }
          }
        }
      }

      &:nth-child(3) {
        &:after {
          content: url(../../images/decor-3.svg);
          position: absolute;
          top: 20rem;
          right: 19.5rem;

          @media only screen and (max-width: 1400px) {
            top: 18rem;
            left: 35.5rem;
          }

          @media only screen and (max-width: 1200px) {
            top: 16.5rem;
            left: 29.75rem;
          }

          @media only screen and (max-width: 992px) {
            display: none;
          }
        }

        .step-content {
          @include box-shadow(-11px 11px 0 0 #E82548, 0px 0px 6px 2px rgba(0,0,0,0.1));

          &:before {
            content: url(../../images/step-decor-1.svg);
            position: absolute;
            top: -10px;
            right: -25px;

            @media only screen and (max-width: 992px) {
              top: -40px;
            }
          }
        }

        .step-img {
          &:before {
            border: 2px solid #E82548;
          }
        }
      }

      @media only screen and (max-width: 576px) {
        display: block;
        padding-bottom: 50px;
      }

      .step-img {
        position: relative;

        @media only screen and (max-width: 992px) {
          width: 100%;
        }

        img {
          position: relative;
          z-index: 20;
          width: 100%;
          display: block;
        }

        &:before,
        &:after {
          content: '';
          display: block;
          position: absolute;
          z-index: 10;
        }

        &:before {
          width: 85%;
          height: 95%;
          right: -10px;
          bottom: -10px;
          background: transparent;
        }

        &:after {
          background: linear-gradient(301.46deg, #EF3355 30.86%, #C766F4 73.16%);
          width: 85%;
          height: 95%;
          left: -10px;
          top: -10px;
        }
      }

      .step-content {
        display: block;
        background-color: #190e2b;
        padding: 32px 40px 32px 52px;
        position: relative;
        z-index: 50;
        left: -100px;

        @media only screen and (max-width: 992px) {
          width: 100%;
          left: unset;
          margin-top: 3rem;
        }

        h3 {
          background-image: none;
          background-clip: unset;
          -webkit-text-fill-color: unset;
          color: #ded5e3;
          font-size: 26px;
          line-height: 39px;
        }

        p {
          font-weight: 400;
          font-size: 16px;
          line-height: 24px;
          color: #ded5e3;
          margin: 0;

          a {
            color: inherit;
            text-decoration: underline;
          }
        }
      }

      div {
        display: block;
        width: 50%;
        position: relative;
        z-index: 50;

        @media only screen and (max-width: 576px) {
          width: 100%;
        }
      }
    }
  }

  .conversion {
    position: relative;
    z-index: 22;
    text-align: center;

    @media only screen and (min-width: 768px) {
      margin-top: -6.5rem;
    }
  }
}
