// Google Fonts
@import url('https://fonts.googleapis.com/css2?family=Karla:wght@300;500;600;700;800&display=swap');

// Bootstrap
@import '~bootstrap/scss/bootstrap';

// Defaults
@import './defaults';

// Variables
@import 'variables/variables--colors';
@import 'variables/variables--fonts';

// Components
@import 'components/components--back-to-home';
@import 'components/components--buttons';
@import 'components/components--custom-checkbox';
@import 'components/components--footer';
@import 'components/components--header';

// Sections
@import 'sections/sections--all';
@import 'sections/sections--hero';
@import 'sections/sections--steps';
@import 'sections/sections--dashboard';
@import 'sections/sections--quote';

// Pages
@import 'pages/pages--privacy';
@import 'pages/pages--proto';
