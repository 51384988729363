@import '~bootstrap/scss/bootstrap.scss';

.VideoContainer {
  @extend .col-lg-5;
  @extend .offset-lg-1;

  position: relative;
  display: flex;
  align-items: center;
  justify-content: center;

  @media only screen and (max-width: 992px) {
    margin-top: 4rem;
  }

  @media only screen and (max-width: 576px) {
    width: 320px !important;
    margin: 0 auto;
  }

  iframe {
    position: relative;
    z-index: 20;

    @media only screen and (max-width: 1280px) {
      max-width: 100%;
    }

    @media only screen and (max-width: 992px) {
      display: block;
      margin: 0 auto;
    }

    @media only screen and (max-width: 576px) {
      width: 320px !important;
    }
  }

  &:after {
    content: '';
    display: block;
    background: linear-gradient(301.46deg, #EF3355 30.86%, #C766F4 73.16%);
    width: 94%;
    height: 48%;
    position: absolute;
    z-index: 10;
    right: -2px;
    top: 173px;

    @media only screen and (max-width: 1399px) {
      width: 90%;
      height: 42%;
      right: 1px;
      top: 184px;
    }

    @media only screen and (max-width: 1280px) {
      width: 90%;
      height: 43%;
      right: 2px;
      top: 179px;
    }

    @media only screen and (max-width: 1199px) {
      height: 26%;
      top: 292px;
    }

    @media only screen and (max-width: 992px) {
      height: 18rem;
      width: 33rem;
      top: -7px;
      right: 79px;
    }

    @media only screen and (max-width: 768px) {
      width: 82%;
      top: 36px;
      right: 29px;
      height: 90%;
    }

    @media only screen and (max-width: 576px) {
      height: 10rem;
      width: 18rem;
      top: 63px;
      right: 3px;
    }
  }
}
