.email {
  text-align: center;
  min-height: 60vh;

  @media only screen and (max-width: 992px) {
    padding-top: 0 !important;
    min-height: auto;
  }

  .form {
    &__img {
      position: relative;

      iframe {
        display: block;
        width: 100%;
        height: 30rem;
        margin: 0 auto;
        position: relative;
        z-index: 20;

        @media only screen and (max-width: 992px) {
          height: 20rem;
        }

        @media only screen and (max-width: 576px) {
          height: 15rem;
        }
      }

      &:before {
        content: '';
        position: absolute;
        background: linear-gradient(301.46deg, #EF3355 30.86%, #C766F4 73.16%);
        width: 65%;
        height: 95%;
        position: absolute;
        z-index: 10;
        top: -13px;
        right: 185px;

        @media only screen and (max-width: 1480px) {
          right: 184px;
        }

        @media only screen and (max-width: 1400px) {
          width: 68%;
          right: 156px;
        }

        @media only screen and (max-width: 1200px) {
          right: 130px;
        }

        @media only screen and (max-width: 992px) {
          right: 94px;
        }

        @media only screen and (max-width: 576px) {
          display: none;
        }
      }

      &:after {
        content: '';
        position: absolute;
        width: 60%;
        height: 95%;
        z-index: 10;
        bottom: -15px;
        left: 190px;
        padding: 2px;
        background: linear-gradient(45deg, #EF3355, #C766F4);
        -webkit-mask:
           linear-gradient(#fff 0 0) content-box,
           linear-gradient(#fff 0 0);
        -webkit-mask-composite: xor;
                mask-composite: exclude;

        @media only screen and (max-width: 1480px) {
          left: 180px;
        }

        @media only screen and (max-width: 1400px) {
          width: 63%;
          left: 153px;
        }

        @media only screen and (max-width: 1200px) {
          left: 125px;
        }

        @media only screen and (max-width: 992px) {
          left: 90px;
        }

        @media only screen and (max-width: 768px) {
          left: 130px;
        }

        @media only screen and (max-width: 576px) {
          display: none;
        }
      }
    }
  }
}
