.Mousey {
  border: 2px solid hsla(0, 0%, 100%, .5);
  border-radius: 25px;
  bottom: -5rem;
  cursor: pointer;
  display: block;
  height: 60px;
  margin: 0 auto;
  opacity: 0.65;
  padding: 10px 15px;
  position: relative;
  transition: all .25s ease;
  width: 37px;

  @media only screen and (max-width: 992px) {
    display: none;
  }

  &:hover {
    opacity: 1;
    transform: scale(1.05);
    transition: all .25s ease;
  }

  > div { // scroller
    animation-duration: 2.2s;
    animation-iteration-count: infinite;
    animation-name: scroll;
    animation-timing-function: cubic-bezier(.15,.41,.69,.94);
    background-color: hsla(0, 0%, 100%, .5);
    border-radius: 25%;
    height: 10px;
    width: 3px;

    @keyframes scroll {
      0% { opacity: 0; }
      10% { transform: translateY(0); opacity: 1; }
      100% { transform: translateY(15px); opacity: 0;}
    }
  }
}
