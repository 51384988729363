@import '~bootstrap/scss/bootstrap.scss';

.Badge {
  @extend .badge;
  @extend .me-1;

  
  background-color: rgba(221, 179, 222, 0.3);
  border-radius: 9px;
  color: #dd499c;
  font-size: 13px;
  text-decoration: none;

  &:hover {
    color: #dd499c;
    text-decoration: underline;
  }
}
