.GradientTextColor {
  background-clip: text;
  -webkit-background-clip: text;
  background-image: linear-gradient(340deg, #EF3355 30%, #C766F4 73%);
  color: transparent;
  -webkit-text-fill-color: transparent;
}

.GradientBg {
  background-color: #EF3355;
  background-image: linear-gradient(45deg, #EF3355, #C766F4, #EF3355, #C766F4);
  background-position: 0;
  background-repeat: no-repeat;
  background-size: 300%;
}

.GradientButton {
  @extend .GradientBg;

  border: 0;
  border-radius: 999px !important;
  box-shadow: 0 12px 24px 0 rgba(0, 0, 0, .141176);
  color: #ffffff;
  cursor: pointer;
  font-family: 'Karla', sans-serif !important;
  font-size: 1.125rem;
  font-weight: 700;
  padding: 0.75rem 1.5rem !important;
  text-decoration: none;
  text-transform: uppercase;
  transition-duration: .3s;
  transition-property: background-position, box-shadow;
  transition-timing-function: ease;

  &:hover {
    background-color: #0aa7e5;
    background-position: 100%;
    border-color: #0a9ed8;
    box-shadow: 0 4px 8px 0 rgba(0, 166, 230, .28) !important;
    color: #ffffff;
  }
}
