.StayCompliant {
  display: flex;
  justify-content: center;
  align-items: center;
  min-height: 70vh;
  padding-top: 0 !important;
  background-image: linear-gradient(35deg,
    rgb(28, 12, 34) 0%,
    rgb(34, 11, 50) 24%,
    rgb(38, 10, 61) 35%,
    rgb(40, 8, 68) 43%,
    rgb(46, 7, 80) 50%,
    rgb(49, 5, 87) 57%,
    rgb(55, 5, 77) 65%,
    rgb(65, 5, 72) 72%,
    rgb(72, 5, 62) 81%,
    rgb(77, 5, 49) 90%,
    rgb(82, 5, 33) 100%);
    box-shadow: inset 0 0 100px hsla(0, 0%, 0%, .3);

  @media only screen and (max-width: 768px) {
    min-height: 82vh;
  }
}

.Partners {
  margin: 3rem 0;
  padding-bottom: .75rem;

  @media only screen and (max-width: 768px) {
    margin-bottom: 0;
  }

  h2 {
    @media only screen and (max-width: 768px) {
      margin-bottom: 0;
    }
  }

  .SliderItem {
    display: flex !important;
    flex-direction: column;
    justify-content: center;
    align-items: center;
    margin: 0 2rem;

    &>div {
      display: flex;
      justify-content: center;
      align-items: center;
      min-height: 120px;
      text-align: center;
    }

    img {
      display: block;
      max-width: 160px;
      max-height: 70px;

      @media only screen and (max-width: 767.98px) {
        max-width: 150px;
        max-height: 80px;
      }
    }

    p {
      font-size: 15px;
      color: #d8d8d8;

      @media only screen and (max-width: 575.98px) {
        font-size: 14px;
      }
    }

    span {
      display: block;
      width: 100%;
      font-size: 14px;
      opacity: 0.75;
    }
  }

  .SliderItemFlex {
    display: flex;
    align-items: center;
    text-align: left;

    img {
      width: 50px;
      margin-right: 25px;

      @media only screen and (max-width: 575.98px) {
        max-width: 150px;
        max-height: 80px;
        margin: 0 auto;
      }
    }

    p {
      margin: 0;
    }
  }
}
